@media (max-width: 768px) {
    header {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: var(--primary);
        text-align: center;
        box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.15);


        img {
            padding-top: 5px;
            max-height: 15vmin;

        }

        .navbarMenu {
            display: none;
        }
    }

    nav.navbar {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        margin-top: 1px;
        color: var(--primary);
        background: linear-gradient(to bottom, var(--pink) 25%, var(--violet) 100%);
        font-size: 1.5rem;

        a {
            width: 100%;
        }

        p {
            width: 100%;
        }
    }

    .navbarClosed {
        display: none;
    }
}

@media (min-width: 768px) {

    header {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: var(--primary);
        text-align: center;
        box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.15);

        img {
            padding-top: 5px;
            height: 7vmin;

        }

        .navbarMenu {

            display: flex;
            height: 100%;
            width: 50%;
            justify-content: space-around;
            font-size: 1.3rem;
            font-weight: 500;

            a {

                transition: transform 0.5s ease-out;

                &:hover {

                    color: var(--pink);
                    transform: scale(1.1);
                    transition: transform 0.1s ease-in;
                }
            }
            
            p {
                transition: transform 0.5s ease-out;
                cursor : pointer;

                &:hover {

                    color: var(--pink);
                    transform: scale(1.1);
                    transition: transform 0.1s ease-in;
                }
            }

        }

    }
}

.navbar {
    display: none;
}

.navbarClosed {
    display: none;
}
