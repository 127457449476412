@media (min-width: 768px) {
  .formContainer {
    padding: 2vmin 0 2vmin 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.25);
  }
  .formContainer .inputWrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
  }
  .formContainer .inputWrapper .message {
    color: red;
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
  }
  .formContainer input {
    padding: 2px;
    font-size: 1rem;
    border-width: 1px;
    border-color: #CCCCCC;
    background-color: #FFFFFF;
    color: #000000;
    border-style: solid;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.75);
  }
  .formContainer select {
    padding: 2px;
    font-size: 1rem;
    border-width: 1px;
    border-color: #CCCCCC;
    background-color: #FFFFFF;
    color: #000000;
    border-style: solid;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.75);
  }
  .main {
    padding: 10px;
  }
  .main .previewContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .formContainer {
    padding: 2vmin 0 2vmin 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.25);
  }
  .formContainer .inputWrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    width: 100%;
    padding: 0px 15px 0px 15px;
  }
  .formContainer .inputWrapper .message {
    color: red;
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
  }
  .formContainer input {
    padding: 2px;
    font-size: 21px;
    border-width: 1px;
    border-color: #CCCCCC;
    background-color: #FFFFFF;
    color: #000000;
    border-style: solid;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.75);
    display: block;
  }
  .formContainer select {
    padding: 2px;
    font-size: 21px;
    border-width: 1px;
    border-color: #CCCCCC;
    background-color: #FFFFFF;
    color: #000000;
    border-style: solid;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.75);
    display: block;
  }
  .main {
    padding: 10px;
  }
  .main .previewContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}/*# sourceMappingURL=Home.module.css.map */