@media (min-width:1024px) {
    .main {
        padding: 20px 0px 20px 0px;

        .container {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-direction: column;

            .estateWrapper {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                background-color: #fff;
                line-height: 1rem;
                width: 100%;
                margin-bottom: 20px;

                img {
                    border-radius: 1rem;
                    width: 80%;
                }


                .nextArrow {

                    color: #fff;
                    font-size: 2rem;
                    border-radius: 100%;
                    background-color: rgba(0, 0, 0, 0.7);
                    cursor: pointer;
                    padding: 1rem;

                }

                .prevArrow {

                    color: #fff;
                    font-size: 2rem;
                    border-radius: 100%;
                    background-color: rgba(0, 0, 0, 0.7);
                    cursor: pointer;
                    padding: 1rem;


                }


            }

            .infoContainer {

                width: 80%;

                .announceWrapper {
                    padding: 1rem;
                    border-radius: 1rem;
                    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
                    background-color: #fff;

                    .seller {
                        text-transform: capitalize;
                    }

                }

                .detail {

                    padding: 1rem;
                    border-radius: 1rem;
                    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
                    background-color: #fff;
                    text-transform: capitalize;
                    font-size: 1.6rem;
                }
            }

        }
    }
}

@media (min-width:460px) and (max-width:1024px) {
    .main {
        padding: 20px 0px 20px 0px;

        .container {
            width: 100%;
            display: flex;
            flex-direction: column;

            .estateWrapper {
                width: 100%;
                box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
                background-color: #fff;
                line-height: 1rem;

                img {
                    width: 100%;
                }



                .nextArrow {
                    width: max-content;
                    color: #fff;
                    font-size: 1rem;
                    border-radius: 100%;
                    background-color: rgba(0, 0, 0, 0.7);
                    cursor: pointer;
                    padding: 0.7rem 1rem;
                    position: absolute;
                    top: 20rem;
                    right: 0;

                }

                .prevArrow {
                    width: max-content;
                    color: #fff;
                    font-size: 1rem;
                    border-radius: 100%;
                    background-color: rgba(0, 0, 0, 0.7);
                    cursor: pointer;
                    padding: 0.7rem 1rem;
                    position: absolute;
                    top: 20rem;

                }
            }

            .infoContainer {
                display: flex;
                flex-direction: column;

                .announceWrapper {
                    padding: 1rem;
                    border-radius: 1rem;
                    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
                    background-color: #fff;

                    .seller {
                        text-transform: capitalize;
                    }

                }

                .detail {

                    padding: 1rem;
                    border-radius: 1rem;
                    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
                    background-color: #fff;
                    margin-top: 1rem;
                    text-transform: capitalize;
                    font-size: 1.6rem;
                }
            }

        }
    }
}

@media (max-width:460px) {
    .main {
        padding: 20px 0px 20px 0px;

        .container {
            width: 100%;
            display: flex;
            flex-direction: column;

            .estateWrapper {
                width: 100%;
                box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
                background-color: #fff;
                line-height: 1rem;

                img {
                    width: 100%;
                }



                .nextArrow {
                    width: max-content;
                    color: #fff;
                    font-size: 1rem;
                    border-radius: 100%;
                    background-color: rgba(0, 0, 0, 0.7);
                    cursor: pointer;
                    padding: 0.7rem 1rem;
                    position: absolute;
                    top: 12rem;
                    right: 0;

                }

                .prevArrow {
                    width: max-content;
                    color: #fff;
                    font-size: 1rem;
                    border-radius: 100%;
                    background-color: rgba(0, 0, 0, 0.7);
                    cursor: pointer;
                    padding: 0.7rem 1rem;
                    position: absolute;
                    top: 12rem;

                }
            }

            .infoContainer {
                display: flex;
                flex-direction: column;
            }

            .announceWrapper {
                padding: 1rem;
                border-radius: 1rem;
                box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
                background-color: #fff;

                .seller {
                    text-transform: capitalize;
                }

            }

            .detail {

                padding: 1rem;
                border-radius: 1rem;
                box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
                background-color: #fff;
                margin-top: 1rem;
                text-transform: capitalize;
                font-size: 1.6rem;
            }
        }

    }
}