@media (max-width: 768px) {

    main {

        h1.title {
            text-align: center;
            margin-top: 5vmin;
        }

        form.LoginForm {

            width: 90%;
            line-height: 2;
            text-align: left;
            box-shadow: 0px 1px 6px 6px rgb(0 0 0 / 8%);
            border-radius: 10px;
            padding: 5vmin;
            margin: 5vmin auto;

            label {
                font-size: 1.2rem;
                font-weight: 400;
                display: block;
            }

            input {
                padding: 2px;
                font-size: 21px;
                border-width: 1px;
                border-color: #CCCCCC;
                background-color: #FFFFFF;
                color: #000000;
                border-style: solid;
                border-radius: 5px;
                box-shadow: 0px 0px 5px rgba(66, 66, 66, .75);
                display: block;
                width: 100%;
            }


            .message {
                color: red;
                font-size: 1.2rem;
                font-weight: 400;
                text-align: center;

            }
        }
    }
}

@media (min-width: 768px) {

    main {

        h1.title {

            text-align: center;
            margin-top: 5vmin;
        }

        form.LoginForm {

            width: 100vmin;
            line-height: 2;
            text-align: left;
            box-shadow: 0px 1px 6px 6px rgb(0 0 0 / 8%);
            border-radius: 10px;
            padding: 5vmin;
            margin: 5vmin auto;

            label {
                font-size: 1.2rem;
                font-weight: 400;
                display: block;
            }

            input {
                padding: 2px;
                font-size: 21px;
                border-width: 1px;
                border-color: #CCCCCC;
                background-color: #FFFFFF;
                color: #000000;
                border-style: solid;
                border-radius: 5px;
                box-shadow: 0px 0px 5px rgba(66, 66, 66, .75);
                display: block;
                width: 100%;
            }


            .message {
                color: red;
                font-size: 1.2rem;
                font-weight: 400;
                text-align: center;

            }
        }
    }
}