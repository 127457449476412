html,
input,
textarea {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

body {
  background-color: var(--primary);
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  color: var(--secondary);
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* End of css reset */
:root {
  --primary: #ffffff;
  --secondary: #191d32;
  --pink: #b72c72;
  --violet: #582f54;
}

button[type="submit"] {

  margin-top: 10px;
  width: 100%;
  box-shadow: inset 0px -3px 7px 0px var(--violet);
  background: linear-gradient(to bottom, var(--pink) 25%, var(--violet) 100%);
  background-color: var(--pink);
  border-radius: 5px;
  border: 1px solid var(--violet);
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 15px;
  padding: 9px 23px;
  text-decoration: none;
  
}

button[type="submit"]:hover {
  background: linear-gradient(to bottom, var(--pink) 1%, var(--violet) 100%);
  background-color: #6f49ac;
}

button[type="submit"]:active {
  position: relative;
  top: 1px;
}