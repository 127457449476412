@media (max-width: 768px) {
  .HamburgerButton {
    width: 49px;
    height: 49px;
    border: 1px solid;
    border-radius: 100%;
    color: var(--secondary);
  }
  .HamburgerButton span {
    position: relative;
    display: block;
    width: 60%;
    height: 1px;
    background-color: var(--secondary);
    top: 22px;
    left: 10px;
  }
  .HamburgerButton:before {
    content: "";
    display: block;
    position: relative;
    background-color: var(--secondary);
    width: 60%;
    height: 1px;
    top: 12px;
    left: 10px;
  }
  .HamburgerButton:after {
    content: "";
    display: block;
    position: relative;
    background-color: var(--secondary);
    width: 60%;
    height: 1px;
    top: 32px;
    left: 10px;
  }
}
@media (min-width: 768px) {
  .HamburgerButton {
    display: none;
  }
}/*# sourceMappingURL=MobileMenuButton.module.css.map */