@media (min-width: 1024px) {
  .main {
    padding: 20px 0px 20px 0px;
  }
  .main .container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .main .container .estateWrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    line-height: 1rem;
    width: 100%;
    margin-bottom: 20px;
  }
  .main .container .estateWrapper img {
    border-radius: 1rem;
    width: 80%;
  }
  .main .container .estateWrapper .nextArrow {
    color: #fff;
    font-size: 2rem;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    padding: 1rem;
  }
  .main .container .estateWrapper .prevArrow {
    color: #fff;
    font-size: 2rem;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    padding: 1rem;
  }
  .main .container .infoContainer {
    width: 80%;
  }
  .main .container .infoContainer .announceWrapper {
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    background-color: #fff;
  }
  .main .container .infoContainer .announceWrapper .seller {
    text-transform: capitalize;
  }
  .main .container .infoContainer .detail {
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    background-color: #fff;
    text-transform: capitalize;
    font-size: 1.6rem;
  }
}
@media (min-width: 460px) and (max-width: 1024px) {
  .main {
    padding: 20px 0px 20px 0px;
  }
  .main .container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .main .container .estateWrapper {
    width: 100%;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    background-color: #fff;
    line-height: 1rem;
  }
  .main .container .estateWrapper img {
    width: 100%;
  }
  .main .container .estateWrapper .nextArrow {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    color: #fff;
    font-size: 1rem;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    padding: 0.7rem 1rem;
    position: absolute;
    top: 20rem;
    right: 0;
  }
  .main .container .estateWrapper .prevArrow {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    color: #fff;
    font-size: 1rem;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    padding: 0.7rem 1rem;
    position: absolute;
    top: 20rem;
  }
  .main .container .infoContainer {
    display: flex;
    flex-direction: column;
  }
  .main .container .infoContainer .announceWrapper {
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    background-color: #fff;
  }
  .main .container .infoContainer .announceWrapper .seller {
    text-transform: capitalize;
  }
  .main .container .infoContainer .detail {
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    background-color: #fff;
    margin-top: 1rem;
    text-transform: capitalize;
    font-size: 1.6rem;
  }
}
@media (max-width: 460px) {
  .main {
    padding: 20px 0px 20px 0px;
  }
  .main .container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .main .container .estateWrapper {
    width: 100%;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    background-color: #fff;
    line-height: 1rem;
  }
  .main .container .estateWrapper img {
    width: 100%;
  }
  .main .container .estateWrapper .nextArrow {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    color: #fff;
    font-size: 1rem;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    padding: 0.7rem 1rem;
    position: absolute;
    top: 12rem;
    right: 0;
  }
  .main .container .estateWrapper .prevArrow {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    color: #fff;
    font-size: 1rem;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    padding: 0.7rem 1rem;
    position: absolute;
    top: 12rem;
  }
  .main .container .infoContainer {
    display: flex;
    flex-direction: column;
  }
  .main .container .announceWrapper {
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    background-color: #fff;
  }
  .main .container .announceWrapper .seller {
    text-transform: capitalize;
  }
  .main .container .detail {
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    background-color: #fff;
    margin-top: 1rem;
    text-transform: capitalize;
    font-size: 1.6rem;
  }
}/*# sourceMappingURL=EstateDetail.module.css.map */